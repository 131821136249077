import React from 'react';

function Hero({ scrollToFormations }) {
  return (
    <section id="hero" className="h-screen flex items-center justify-center">
      <div className="text-center">
      <img src={'./assets/logo_camara_formation.png'} alt="Camara Formation Logo" className="mx-auto mb-4 w-80 h-32 cursor-pointer" />
        <h1 className="text-4xl font-bold text-red-800">Bienvenue chez NEW FORMATION</h1>
        <p className="mt-4 text-red-600">Découvrez nos formations professionnelles adaptées à vos besoins.</p>
        <div className="mt-6 text-red-600">
          <p><strong>Email</strong> : <a href="mailto:New.formation.pro@gmail.com" className="hover:underline">New.formation.pro@gmail.com</a></p>
          <p><strong>Téléphone</strong> : <a href="tel:+33753001217" className="hover:underline">07 53 00 12 17</a></p>
          <p><strong>Adresse postale</strong> : 14 avenue du 8 mai 1945, Sarcelles</p>
        </div>
        <button 
          onClick={scrollToFormations} 
          className="mt-6 inline-block bg-red-600 text-white py-2 px-4 rounded-full hover:bg-red-700 transition duration-300">
          Voir les formations
        </button>
      </div>
    </section>
  );
}

export default Hero;
