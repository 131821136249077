import React, { useState } from 'react';

function FormationCard({ title, description, duration, price, accessDuration }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  const getButtonText = () => {
    if (isOpen) {
      return 'Réduire';
    } else {
      return 'En savoir plus';
    }
  };

  return (
    <div className="bg-red-100 p-6 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
      <p className="text-red-600 mb-4">{description}</p>

      <button 
        onClick={toggleDetails} 
        className="text-red-600 hover:underline font-bold">
        {getButtonText()}
      </button>

      {isOpen && (
        <div className="mt-4 bg-red-50 p-4 rounded-lg shadow-inner transition-all duration-300">
          <p className="text-gray-700"><strong>Durée :</strong> {duration}</p>
          <p className="text-gray-700"><strong>Tarif :</strong> {price}</p>
          <p className="text-gray-700"><strong>Accès :</strong> {accessDuration}</p>
        </div>
      )}
    </div>
  );
}

function Formations({ formationRef }) {
  return (
    <section id="formations" ref={formationRef} className="py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Nos Formations</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-5">
          
          <div className="transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="Adobe INDESIGN 2021 | De A à Z (Certifiant)" 
              description="Formation complète sur Adobe Indesign de A à Z." 
              duration="15 heures" 
              price="1 600 €" 
              accessDuration="12 Mois" 
            />
          </div>

          <div className="transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="SKETCHUP PRO 2018-2021-2023 avec Ateliers décoration intérieur" 
              description="Découverte approfondie de SketchUp Pro avec ateliers pratiques." 
              duration="19 heures" 
              price="2 300 €" 
              accessDuration="12 Mois" 
            />
          </div>

          <div className="transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="AutoDesk REVIT Architecture (Certifiant)" 
              description="Apprentissage de REVIT pour l'architecture avec certification." 
              duration="22 heures" 
              price="2 800 €" 
              accessDuration="12 Mois" 
            />
          </div>

          <div className="transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="Microsoft WORD 2021 tous niveaux (Certifiant)" 
              description="Apprentissage complet de Microsoft Word, interactif et adaptatif." 
              duration="19 heures" 
              price="2 850 €" 
              accessDuration="12 Mois" 
            />
          </div>

          <div className="transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="Microsoft EXCEL 2021 tous niveaux (Certifiant)" 
              description="Formation complète sur Microsoft Excel avec logiciels intégrés." 
              duration="16 heures" 
              price="1 800 €" 
              accessDuration="12 Mois" 
            />
          </div>

          <div className="transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="Adobe ILLUSTRATOR 2021 | Les bases (Certifiant)" 
              description="Apprentissage des bases d'Adobe Illustrator pour les débutants." 
              duration="8 heures" 
              price="1 200 €" 
              accessDuration="12 Mois" 
            />
          </div>

          <div className="lg:col-span-3 flex justify-center transition-transform duration-300 hover:scale-105">
            <FormationCard 
              title="Anglais Tous niveaux en E-learning (Certifiant)" 
              description="Formation en ligne pour tous niveaux en anglais, avec certification." 
              duration="105 heures" 
              price="1 500 €" 
              accessDuration="12 Mois" 
            />
          </div>
        </div>

        <div className="text-center mt-12 text-gray-800">
          <p>Pour en savoir plus, merci de nous envoyer un email à <a href="mailto:New.formation.pro@gmail.com" className="text-red-600 hover:underline">New.formation.pro@gmail.com</a> ou de nous joindre par téléphone au <a href="tel:+33753001217" className="text-red-600 hover:underline">07 53 00 12 17</a>.</p>
        </div>
        
      </div>
    </section>
  );
}

export default Formations;
