import React, { useRef, useEffect } from 'react';
import './App.css';
import Hero from './components/Hero';
import Formations from './components/Formations';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const formationRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const scrollToFormations = () => {
    formationRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <div className="bg-gradient-to-t from-white to-red-200 min-h-screen">
        <Hero scrollToFormations={scrollToFormations} />

        <div data-aos="zoom-in">
          <Formations formationRef={formationRef} className />
        </div>  
      
      
      </div>
      <Footer />
    </div>
  );
}

export default App;
