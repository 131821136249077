import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-800 py-6 text-white text-center">
      <p>© 2024 NEW FORMATION - Tous droits réservés.</p>

      <div className="mt-4">
        <p><strong>Email</strong> : <a href="mailto:New.formation.pro@gmail.com" className="hover:underline">New.formation.pro@gmail.com</a></p>
        <p><strong>Téléphone</strong> : <a href="tel:+33753001217" className="hover:underline">07 53 00 12 17</a></p>
        <p><strong>Adresse</strong> : 14 avenue du 8 mai 1945, Sarcelles</p>
      </div>
    </footer>
  );
}

export default Footer;
